var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CCard',[_c('CCardBody',[_c('h4',[_vm._v("Roles")]),_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createRole()}}},[_vm._v("Create Role")]),_c('CAlert',{attrs:{"show":_vm.dismissCountDown,"color":"primary","fade":""},on:{"update:show":function($event){_vm.dismissCountDown=$event}}},[_vm._v(" ("+_vm._s(_vm.dismissCountDown)+") "+_vm._s(_vm.message)+" ")]),_c('CDataTable',{attrs:{"hover":"","items":_vm.items,"fields":_vm.fields,"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"hierarchy",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.hierarchy))])])]}},{key:"move-up",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveUp( item.id )}}},[_vm._v("Move Up")])],1)]}},{key:"move-down",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveDown( item.id )}}},[_vm._v("Move Down")])],1)]}},{key:"show",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showRole( item.id )}}},[_vm._v("Show")])],1)]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editRole( item.id )}}},[_vm._v("Edit")])],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteRole( item.id )}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }